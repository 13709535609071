import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Send } from 'lucide-react';
import { Alert, AlertDescription } from './ui/alert';

const NBAStatsQueryPage = () => {
  const [prompt, setPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!prompt.trim()) return;

    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch('/api/nba/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
      
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      
      const result = await response.json();
      
      if (result.error) {
        throw new Error(result.error);
      }
      
      setData(result.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Example queries for users
  const exampleQueries = [
    "Show me players with most STOCKS",
    "Who has the highest STOCKS?",
    "Show most recent STOCKS records"
  ];

  return (
    <div className="space-y-4 w-full max-w-4xl mx-auto p-4">
      <Card className="w-full">
        <CardHeader>
          <CardTitle className="text-xl font-bold text-primary">NBA Stats Query Interface</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <form onSubmit={handleSubmit} className="flex gap-4 items-end">
            <div className="flex-1 space-y-2">
              <label htmlFor="prompt" className="text-sm font-medium text-primary">
                Enter your query
              </label>
              <Input
                id="prompt"
                placeholder="Which players have had the most STOCKS in a single postseason game?"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="w-full"
              />
            </div>
            <Button 
              type="submit" 
              disabled={loading} 
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              <Send className="h-4 w-4 mr-2" />
              {loading ? 'Querying...' : 'Ask'}
            </Button>
          </form>
          
          <div className="space-y-2">
            <p className="text-sm text-muted-foreground">Example queries:</p>
            <div className="flex flex-wrap gap-2">
              {exampleQueries.map((query, index) => (
                <Button
                  key={index}
                  variant="secondary"
                  size="sm"
                  onClick={() => setPrompt(query)}
                  className="whitespace-nowrap"
                >
                  {query}
                </Button>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>

      {error && (
        <Alert variant="destructive">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {data.length > 0 && (
        <Card className="w-full">
          <CardHeader>
            <CardTitle className="text-xl font-bold text-primary">Top Players by Stocks in Postseason</CardTitle>
            <p className="text-sm text-secondary">STOCKS = Steals + Blocks in a single postseason game</p>
          </CardHeader>
          <CardContent>
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="border-b">
                    <th className="text-left p-4 text-primary">PLAYER NAME</th>
                    <th className="text-left p-4 text-primary">TEAM</th>
                    <th className="text-left p-4 text-primary">STOCKS</th>
                    <th className="text-left p-4 text-primary">DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((player, index) => (
                    <tr 
                      key={index} 
                      className="border-b hover:bg-hover transition-colors"
                    >
                      <td className="p-4 font-medium text-primary">{player.playerName}</td>
                      <td className="p-4 text-primary">{player.team}</td>
                      <td className="p-4">
                        <div className="flex items-center gap-2">
                          <span className="font-medium text-primary">{player.stocks}</span>
                          <div className="relative w-24 h-2 bg-progress-background rounded">
                            <div 
                              className="absolute top-0 left-0 h-full bg-progress-bar rounded"
                              style={{ 
                                width: `${(player.stocks / 12) * 100}%`
                              }}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="p-4 text-primary">{formatDate(player.date)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default NBAStatsQueryPage; 