import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import { MessageSquare, Info, Mail, Menu, X, Sun, Moon} from 'lucide-react';
// import { Database, Search, Camera } from 'lucide-react';

import ChatInterface from './components/ChatInterface';
import StatsExplorer from './components/StatsExplorer';
import AboutPage from './components/AboutPage';
import ContactPage from './components/ContactPage';
import AIStatCheck from './components/AIStatCheck';
import NBAStatsQueryPage from './components/NBAStatsQueryPage';

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(true);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      if (newMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
      return newMode;
    });
  };

  // Add useEffect to handle initial dark mode
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    }
  }, [isDarkMode]);

  return (
    <Router>
      <div className="App flex flex-col min-h-screen">
        {/* Dark Mode Toggle Button */}
        <button
          hidden="True" /*TODO: FIX dark/light mode toggle*/
          onClick={toggleDarkMode}
          className="fixed top-4 right-4 z-50 p-2 rounded-full bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors duration-200"
          aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        >
          {isDarkMode ? <Sun className="h-5 w-5 text-yellow-400" /> : <Moon className="h-5 w-5 text-gray-800" />}
        </button>

        {/* Hamburger Menu Button */}
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="fixed top-4 left-4 z-50 p-2 rounded-lg hover:bg-gray-700 transition-colors"
          aria-label={isSidebarOpen ? "Close menu" : "Open menu"}
        >
          {isSidebarOpen ? (
            <X className="w-6 h-6 text-white" />
          ) : (
            <Menu className="w-6 h-6 text-white" />
          )}
        </button>

        {/* Overlay */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 transition-opacity"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}

        { /* Sidebar Navigation */}
        <nav
          className={`fixed left-0 top-0 h-full w-64 bg-gray-900 text-gray-300 p-4 overflow-y-auto z-40 transform transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
          <div className="mb-8 pt-14">
            <h1 className="text-xl font-bold text-white mb-4">StatMuseHater</h1>
          </div>

          <div className="space-y-2">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `flex items-center px-4 py-3 rounded-lg transition-colors ${isActive
                  ? 'bg-blue-600 text-white'
                  : 'hover:bg-gray-800 hover:text-white'
                }`
              }
              onClick={() => setIsSidebarOpen(false)}
            >
              <MessageSquare className="w-5 h-5 mr-3" />
              <span>NBA Stats AI</span>
            </NavLink>

            {/* <NavLink
              to="/aistatsquery"
              className={({ isActive }) =>
                `flex items-center px-4 py-3 rounded-lg transition-colors ${isActive
                  ? 'bg-blue-600 text-white'
                  : 'hover:bg-gray-800 hover:text-white'
                }`
              }
              onClick={() => setIsSidebarOpen(false)}
            >
              <Search className="w-5 h-5 mr-3" />
              <span>Query</span>
            </NavLink>

            <NavLink
              to="/aistatcheck"
              className={({ isActive }) =>
                `flex items-center px-4 py-3 rounded-lg transition-colors ${isActive
                  ? 'bg-blue-600 text-white'
                  : 'hover:bg-gray-800 hover:text-white'
                }`
              }
              onClick={() => setIsSidebarOpen(false)}
            >
              <Camera className="w-5 h-5 mr-3" />
              <span>Stat Check</span>
            </NavLink>

            <NavLink
              to="/statsexplorer"
              className={({ isActive }) =>
                `flex items-center px-4 py-3 rounded-lg transition-colors ${isActive
                  ? 'bg-blue-600 text-white'
                  : 'hover:bg-gray-800 hover:text-white'
                }`
              }
              onClick={() => setIsSidebarOpen(false)}
            >
              <Database className="w-5 h-5 mr-3" />
              <span>Explorer</span>
            </NavLink> */}

          </div>

          {/* Additional Links Section */}
          <div className="mt-8 pt-8 border-t border-gray-700">
            <h2 className="text-sm uppercase text-gray-500 mb-4 px-4">More</h2>
            <div className="space-y-2">
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  `flex items-center px-4 py-2 rounded-lg transition-colors ${isActive
                    ? 'bg-blue-600 text-white'
                    : 'hover:bg-gray-800 hover:text-white'
                  }`
                }
                onClick={() => setIsSidebarOpen(false)}
              >
                <Info className="w-4 h-4 mr-3" />
                <span>About</span>
              </NavLink>

              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  `flex items-center px-4 py-2 rounded-lg transition-colors ${isActive
                    ? 'bg-blue-600 text-white'
                    : 'hover:bg-gray-800 hover:text-white'
                  }`
                }
                onClick={() => setIsSidebarOpen(false)}
              >
                <Mail className="w-4 h-4 mr-3" />
                <span>Contact</span>
              </NavLink>
            </div>
          </div>
        </nav>

        {/* Main Content Area */}
        <div className={`flex-grow flex flex-col transition-margin duration-300 ease-in-out ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
          <main className="flex-1 p-6 pt-16 flex flex-col">
            <Routes>
              <Route path="/" element={<ChatInterface />} />
              <Route path="/statsexplorer" element={<StatsExplorer />} />
              <Route path="/aistatsquery" element={<NBAStatsQueryPage />} />
              <Route path="/aistatcheck" element={<AIStatCheck />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </main>
          {/* <Footer /> */}
        </div>
      </div>
    </Router>
  );
}

export default App;