
import React from 'react';

function AboutPage() {
  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">About Us</h1>
      <div className="mt-8 p-4 bg-gray-100 rounded-lg text-sm text-gray-600">
        <p>This website is not affiliated with, endorsed by, or associated with the National Basketball Association (NBA) or any of its teams.</p>
      </div>
    </div>
  );
}

export default AboutPage;