import React, { useState, useEffect } from 'react';
import {
    Search,
    SlidersHorizontal,
    Loader
} from 'lucide-react';
import axios from 'axios';

// Updated API_URL to use HTTP instead of HTTPS
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9001';

const StatsExplorer = () => {
    const [activeTab, setActiveTab] = useState('player_season');
    const [searchQuery, setSearchQuery] = useState('');
    const [playerData, setPlayerData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'pts', direction: 'descending' });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [seasonFilter, setSeasonFilter] = useState('2024-25');
    const [isLoading, setIsLoading] = useState(false);
    const [statMode, setStatMode] = useState('Per Game');
    const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
    
    // Update filterableColumns to include shooting stats
    const filterableColumns = [
        { key: 'gp', label: 'Games Played' },
        { key: 'gs', label: 'Games Started' },
        { key: 'min', label: 'Minutes' },
        { key: 'pts', label: 'Points' },
        { key: 'reb', label: 'Rebounds' },
        { key: 'ast', label: 'Assists' },
        { key: 'stl', label: 'Steals' },
        { key: 'blk', label: 'Blocks' },
        { key: 'tov', label: 'Turnovers' },
        { key: 'fouls', label: 'Fouls' },
        // Add shooting stats
        { key: 'fgm', label: 'FG Made' },
        { key: 'fga', label: 'FG Attempts' },
        { key: 'fg%', label: 'FG%', isPercentage: true },
        { key: 'threepm', label: '3P Made' },
        { key: 'threepa', label: '3P Attempts' },
        { key: 'threep%', label: '3P%', isPercentage: true },
        { key: 'ftm', label: 'FT Made' },
        { key: 'fta', label: 'FT Attempts' },
        { key: 'ft%', label: 'FT%', isPercentage: true }
    ];
    const [visibleColumns, setVisibleColumns] = useState(filterableColumns.map(col => col.key));

    useEffect(() => {
        // Fetch data from the backend
        const fetchData = async () => {
            setIsLoading(true);
            try {
                console.log('Fetching data for season:', seasonFilter);
                if (API_URL === undefined || API_URL === '') {
                    throw new Error('API_URL environment variable is not set');
                }
                const response = await axios.get(`${API_URL}/api/players`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    params: { season_id: seasonFilter, stat_mode: statMode }
                });
                console.log('Data fetched:', response.data);
                setPlayerData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [seasonFilter, statMode]); // Added statMode as a dependency

    // Generate array of seasons from 1996-97 to 2024-25
    const seasons = React.useMemo(() => {
        const seasonArray = [];
        for (let year = 2024; year >= 1996; year--) {
            seasonArray.push(`${year}-${(year + 1).toString().slice(2)}`);
        }
        return seasonArray;
    }, []);


    // Update handleFilterChange to handle percentage values
    const handleFilterChange = (column, type, value) => {
        const columnDef = filterableColumns.find(col => col.key === column);
        let processedValue = value === '' ? undefined : Number(value);

        // Convert percentage inputs to decimal for internal storage
        if (columnDef?.isPercentage && processedValue !== undefined) {
            processedValue = processedValue / 100;
        }

        setFilters(prev => ({
            ...prev,
            [column]: {
                ...prev[column],
                [type]: processedValue
            }
        }));
    };

    // Add this function to remove a specific filter
    const removeFilter = (column, type) => {
        setFilters(prev => ({
            ...prev,
            [column]: {
                ...prev[column],
                [type]: undefined
            }
        }));

        //   // Clean up empty filter objects
        //   if (!prev[column].min && !prev[column].max) {
        //       const newFilters = { ...prev };
        //       delete newFilters[column];
        //       return newFilters;
        //   }
        //   return prev;
    };

    // Add this function to format the filter value display
    const formatFilterValue = (value, column) => {
        // You can add special formatting for specific columns here if needed
        return value.toString();
    };

    // Update filteredData to handle percentage comparisons
    const filteredData = playerData.filter((player) => {
        if (!player.player.toLowerCase().includes(searchQuery.toLowerCase())) {
            return false;
        }

        if (seasonFilter && player.season !== seasonFilter) {
            return false;
        }

        for (const [column, range] of Object.entries(filters)) {
            let value;

            // Calculate percentages for comparison
            if (column === 'fg%') {
                value = player.fga === 0 ? 0 : player.fgm / player.fga;
            } else if (column === 'threep%') {
                value = player.threepa === 0 ? 0 : player.threepm / player.threepa;
            } else if (column === 'ft%') {
                value = player.fta === 0 ? 0 : player.ftm / player.fta;
            } else {
                value = player[column];
            }

            if (range.min !== undefined && value < range.min) return false;
            if (range.max !== undefined && value > range.max) return false;
        }
        return true;
    });

    // Add this function to calculate stats based on mode
    const calculateStats = (player) => {
        const stats = { ...player };
        const multiplier = statMode === 'Per 36' ? (36 / (player.min / player.gp)) : 1;
        const divisor = statMode === 'Per Game' ? player.gp : 1;

        // List of stats to adjust
        const adjustableStats = ['min', 'pts', 'reb', 'ast', 'stl', 'blk', 'tov', 'fouls',
            'fgm', 'fga', 'threepm', 'threepa', 'ftm', 'fta'];

        if (statMode !== 'Total') {
            adjustableStats.forEach(stat => {
                if (statMode === 'Per Game') {
                    stats[stat] = (player[stat] / divisor).toFixed(1);
                } else if (statMode === 'Per 36') {
                    stats[stat] = ((player[stat] / player.gp) * multiplier).toFixed(1);
                }
            });
        }

        return stats;
    };

    // Modify the sortedData calculation to use calculated stats
    const sortedData = React.useMemo(() => {
        let sortableData = [...filteredData].map(player => calculateStats(player));
        if (sortConfig !== null) {
            sortableData.sort((a, b) => {
                // Special handling for percentage columns
                const getPercentage = (item, made, attempted) =>
                    item[attempted] === 0 ? null : (item[made] / item[attempted]) * 100;

                let aValue, bValue;
                if (sortConfig.key === 'fg%') {
                    aValue = getPercentage(a, 'fgm', 'fga');
                    bValue = getPercentage(b, 'fgm', 'fga');
                } else if (sortConfig.key === 'threep%') {
                    aValue = getPercentage(a, 'threepm', 'threepa');
                    bValue = getPercentage(b, 'threepm', 'threepa');
                } else if (sortConfig.key === 'ft%') {
                    aValue = getPercentage(a, 'ftm', 'fta');
                    bValue = getPercentage(b, 'ftm', 'fta');
                } else {
                    aValue = a[sortConfig.key];
                    bValue = b[sortConfig.key];
                }

                // Function to check if value is empty
                const isEmpty = (val) => val === null || val === undefined || val === '';

                // Place empty values at the bottom for both ascending and descending
                const aIsEmpty = isEmpty(aValue);
                const bIsEmpty = isEmpty(bValue);
                if (aIsEmpty && bIsEmpty) return 0;
                if (aIsEmpty) return 1;
                if (bIsEmpty) return -1;

                // Convert values to numbers if possible
                const aNum = parseFloat(aValue);
                const bNum = parseFloat(bValue);

                const aNumIsNaN = isNaN(aNum);
                const bNumIsNaN = isNaN(bNum);

                // Treat NaN results as empty values and place them at the bottom
                if (aNumIsNaN && bNumIsNaN) {
                    return sortConfig.direction === 'ascending'
                        ? String(aValue).localeCompare(String(bValue))
                        : String(bValue).localeCompare(String(aValue));
                }
                if (aNumIsNaN) return 1;
                if (bNumIsNaN) return -1;

                // Final numerical comparison based on sort direction
                return sortConfig.direction === 'ascending' ? aNum - bNum : bNum - aNum;
            });
        }
        return sortableData;
    }, [filteredData, sortConfig, statMode]);


    const requestSort = (key) => {
        let direction = 'descending';
        if (sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return '';
    };

    const tabs = [
        { key: 'player_season', label: 'Player Season Search' },
        { key: 'player_game', label: 'Player Game Search', disabled: true }
    ];

    const toggleColumnVisibility = (key) => {
        //   setVisibleColumns(prev =>
        //       prev.includes(key) ? prev.filter(col => col !== key) : [...prev, key]
        //   );
    };

    return (
        <div className="flex flex-col flex-1 max-w-3xl mx-auto p-4">
            {/* Header */}
            <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">
                    🏀 StatMuseHater
                    <span className="align-middle text-white bg-gray-600 text-xs px-1 pt-1.4 pb-1.4">
                        BETA
                    </span>
                </h3>
            </div>

            {/* Tabs */}
            <div className="flex space-x-4 mb-8">
                {tabs.map((tab) => (
                    <button
                        key={tab.key}
                        className={`px-4 py-2 rounded transition-colors duration-200 ${activeTab === tab.key
                            ? 'bg-blue-600 text-white'
                            : 'bg-gray-100 dark:bg-gray-700 dark:text-gray-300'
                            }`}
                        onClick={() => !tab.disabled && setActiveTab(tab.key)}
                        aria-pressed={activeTab === tab.key}
                        disabled={tab.disabled}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* Add Stat Mode selector after the tabs */}
            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <span className="text-gray-700 dark:text-gray-300">Stat Mode:</span>
                    <select
                        value={statMode}
                        onChange={(e) => setStatMode(e.target.value)}
                        className="px-3 py-1 border dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                    >
                        <option value="Per Game">Per Game</option>
                        <option value="Total">Total</option>
                        <option value="Per 36">Per 36</option>
                    </select>
                </div>
            </div>

            {/* Search and Filters */}
            <div className="mb-6">
                <div className="flex gap-4 mb-4">
                    <div className="flex-1 relative">
                        <input
                            type="text"
                            placeholder="Search players..."
                            className="w-full p-2 pl-10 border dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            aria-label="Search players"
                        />
                        <Search
                            className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </div>

                    {/* Add Season Filter Dropdown */}
                    <select
                        value={seasonFilter}
                        onChange={(e) => setSeasonFilter(e.target.value)}
                        className="px-4 py-2 border dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                    >
                        <option value="">All Seasons</option>
                        {seasons.map(season => (
                            <option key={season} value={season}>
                                {season}
                            </option>
                        ))}
                    </select>

                    <button
                        className="flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-300"
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                        <SlidersHorizontal className="h-5 w-5" aria-hidden="true" />
                        Filters
                    </button>
                    <button
                        className="flex items-center gap-2 px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-300"
                        onClick={() => setIsColumnSelectorOpen(!isColumnSelectorOpen)}
                    >
                        <SlidersHorizontal className="h-5 w-5" aria-hidden="true" />
                        Stats
                    </button>
                </div>

                {/* Active Filters */}
                <div className="flex flex-wrap gap-2 mb-4">
                    {seasonFilter && (
                        <span className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-sm flex items-center gap-1">
                            {`Season: ${seasonFilter}`}
                            <button
                                className="ml-1 hover:text-blue-600 dark:hover:text-blue-400"
                                onClick={() => setSeasonFilter('')}
                                aria-label="Remove season filter"
                            >
                                ×
                            </button>
                        </span>
                    )}
                    {Object.entries(filters).map(([column, range]) => {
                        const columnLabel = filterableColumns.find(col => col.key === column)?.label || column;
                        return (
                            <React.Fragment key={column}>
                                {range.min !== undefined && (
                                    <span className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-sm flex items-center gap-1">
                                        {`${columnLabel} ≥ ${formatFilterValue(range.min, column)}`}
                                        <button
                                            className="ml-1 hover:text-blue-600 dark:hover:text-blue-400"
                                            onClick={() => removeFilter(column, 'min')}
                                            aria-label={`Remove minimum ${columnLabel} filter`}
                                        >
                                            ×
                                        </button>
                                    </span>
                                )}
                                {range.max !== undefined && (
                                    <span className="px-3 py-1 bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-200 rounded-full text-sm flex items-center gap-1">
                                        {`${columnLabel} ≤ ${formatFilterValue(range.max, column)}`}
                                        <button
                                            className="ml-1 hover:text-blue-600 dark:hover:text-blue-400"
                                            onClick={() => removeFilter(column, 'max')}
                                            aria-label={`Remove maximum ${columnLabel} filter`}
                                        >
                                            ×
                                        </button>
                                    </span>
                                )}
                            </React.Fragment>
                        );
                    })}
                    {Object.keys(filters).length > 0 && (
                        <button
                            onClick={() => setFilters({})}
                            className="px-3 py-1 bg-gray-100 dark:bg-gray-700 text-gray-600 dark:text-gray-300 rounded-full text-sm hover:bg-gray-200 dark:hover:bg-gray-600"
                        >
                            Clear all filters
                        </button>
                    )}
                </div>
            </div>

            {/* Results */}
            <div className="mb-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                        Results ({filteredData.length})
                    </h2>
                </div>

                {isLoading ? (
                    <div className="flex justify-center items-center">
                        <Loader className="animate-spin h-8 w-8 text-blue-500" />
                        <span className="ml-2 text-blue-500">Loading...</span>
                    </div>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="w-full text-sm">
                            <thead>
                                <tr className="bg-gray-50 dark:bg-gray-700 border-y dark:border-gray-600">
                                    <th className="p-3 text-left text-gray-900 dark:text-gray-100">#</th>
                                    <th className="p-3 text-left text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('player')}>
                                        Player {getSortIcon('player')}
                                    </th>
                                    <th className="p-3 text-left text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('season')}>
                                        Season {getSortIcon('season')}
                                    </th>
                                    <th className="p-3 text-left text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('team')}>
                                        Team {getSortIcon('team')}
                                    </th>
                                    {visibleColumns.includes('gp') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('gp')}>
                                            GP {getSortIcon('gp')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('gs') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('gs')}>
                                            GS {getSortIcon('gs')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('min') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('min')}>
                                            MIN {getSortIcon('min')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('pts') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('pts')}>
                                            PTS {getSortIcon('pts')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('reb') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('reb')}>
                                            REB {getSortIcon('reb')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('ast') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('ast')}>
                                            AST {getSortIcon('ast')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('stl') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('stl')}>
                                            STL {getSortIcon('stl')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('blk') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('blk')}>
                                            BLK {getSortIcon('blk')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('tov') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('tov')}>
                                            TOV {getSortIcon('tov')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('fouls') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('fouls')}>
                                            FLS {getSortIcon('fouls')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('fgm') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('fgm')}>
                                            FGM {getSortIcon('fgm')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('fga') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('fga')}>
                                            FGA {getSortIcon('fga')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('fg%') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('fg%')}>
                                            FG% {getSortIcon('fg%')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('threepm') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('threepm')}>
                                            3PM {getSortIcon('threepm')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('threepa') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('threepa')}>
                                            3PA {getSortIcon('threepa')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('threep%') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('threep%')}>
                                            3P% {getSortIcon('threep%')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('ftm') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('ftm')}>
                                            FTM {getSortIcon('ftm')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('fta') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('fta')}>
                                            FTA {getSortIcon('fta')}
                                        </th>
                                    )}
                                    {visibleColumns.includes('ft%') && (
                                        <th className="p-3 text-right text-gray-900 dark:text-gray-100 cursor-pointer" onClick={() => requestSort('ft%')}>
                                            FT% {getSortIcon('ft%')}
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((row, index) => (
                                    <tr
                                        key={index}
                                        className="border-b dark:border-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors duration-200"
                                    >
                                        <td className="p-3 text-left text-gray-900 dark:text-gray-100">
                                            {index + 1}
                                        </td>
                                        <td className="p-3 font-medium text-blue-600 dark:text-blue-400">
                                            {row.player}
                                        </td>
                                        <td className="p-3 text-gray-900 dark:text-gray-100">{row.season}</td>
                                        <td className="p-3 text-gray-900 dark:text-gray-100">{row.team}</td>
                                        {visibleColumns.includes('gp') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.gp}
                                            </td>
                                        )}
                                        {visibleColumns.includes('gs') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.gs}
                                            </td>
                                        )}
                                        {visibleColumns.includes('min') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.min}
                                            </td>
                                        )}
                                        {visibleColumns.includes('pts') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.pts}
                                            </td>
                                        )}
                                        {visibleColumns.includes('reb') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.reb}
                                            </td>
                                        )}
                                        {visibleColumns.includes('ast') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.ast}
                                            </td>
                                        )}
                                        {visibleColumns.includes('stl') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.stl}
                                            </td>
                                        )}
                                        {visibleColumns.includes('blk') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.blk}
                                            </td>
                                        )}
                                        {visibleColumns.includes('tov') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.tov}
                                            </td>
                                        )}
                                        {visibleColumns.includes('fouls') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.fouls}
                                            </td>
                                        )}
                                        {visibleColumns.includes('fgm') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.fgm}
                                            </td>
                                        )}
                                        {visibleColumns.includes('fga') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.fga}
                                            </td>
                                        )}
                                        {visibleColumns.includes('fg%') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.fga == 0 ? '' : ((row.fgm / row.fga) * 100).toFixed(1) + '%'}
                                            </td>
                                        )}
                                        {visibleColumns.includes('threepm') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.threepm}
                                            </td>
                                        )}
                                        {visibleColumns.includes('threepa') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.threepa}
                                            </td>
                                        )}
                                        {visibleColumns.includes('threep%') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.threepa == 0 ? '' : ((row.threepm / row.threepa) * 100).toFixed(1) + '%'}
                                            </td>
                                        )}
                                        {visibleColumns.includes('ftm') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.ftm}
                                            </td>
                                        )}
                                        {visibleColumns.includes('fta') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.fta}
                                            </td>
                                        )}
                                        {visibleColumns.includes('ft%') && (
                                            <td className="p-3 text-right text-gray-900 dark:text-gray-100">
                                                {row.fta == 0 ? '' : ((row.ftm / row.fta) * 100).toFixed(1) + '%'}
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>

            {/* Pagination */}
            <div className="flex justify-between items-center">
                <span className="text-sm text-gray-600 dark:text-gray-400">
                    Showing 1-{filteredData.length} of {filteredData.length} results
                </span>
                <div className="flex gap-2">
                    <button
                        className="px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-300"
                        disabled
                    >
                        Previous
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-100 dark:bg-gray-700 rounded dark:text-gray-300"
                        disabled
                    >
                        Next
                    </button>
                </div>
            </div>

            {/* Add the Filter Modal */}
            {isFilterOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[80vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold dark:text-white">Filters</h2>
                            <button
                                onClick={() => setIsFilterOpen(false)}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                ×
                            </button>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {filterableColumns.map(({ key, label }) => (
                                <div key={key} className="space-y-2">
                                    <label className="block text-sm font-medium dark:text-gray-200">
                                        {label}
                                    </label>
                                    <div className="flex gap-2">
                                        <input
                                            type="number"
                                            placeholder="Min"
                                            className="w-full p-2 border dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                                            value={filters[key]?.min || ''}
                                            onChange={(e) => handleFilterChange(key, 'min', e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && setIsFilterOpen(false)}
                                        />
                                        <input
                                            type="number"
                                            placeholder="Max"
                                            className="w-full p-2 border dark:border-gray-600 rounded bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
                                            value={filters[key]?.max || ''}
                                            onChange={(e) => handleFilterChange(key, 'max', e.target.value)}
                                            onKeyDown={(e) => e.key === 'Enter' && setIsFilterOpen(false)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-6 flex justify-end gap-2">
                            <button
                                onClick={() => setFilters({})}
                                className="px-4 py-2 text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                            >
                                Reset
                            </button>
                            <button
                                onClick={() => setIsFilterOpen(false)}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Column Selector Dialog */}
            {isColumnSelectorOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-xl max-w-2xl w-full max-h-[80vh] overflow-y-auto">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl font-bold dark:text-white">Select Visible Stats</h2>
                            <button
                                onClick={() => setIsColumnSelectorOpen(false)}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                ×
                            </button>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {filterableColumns.map(({ key, label }) => (
                                <div key={key} className="flex items-center space-x-2">
                                    <input
                                        type="checkbox"
                                        checked={visibleColumns.includes(key)}
                                        onChange={() => toggleColumnVisibility(key)}
                                    />
                                    <label className="text-sm font-medium dark:text-gray-200">{label}</label>
                                </div>
                            ))}
                        </div>
                        <div className="mt-6 flex justify-end gap-2">
                            <button
                                onClick={() => setIsColumnSelectorOpen(false)}
                                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
  );
}

export default StatsExplorer;