import React, { useState, useRef } from 'react';

const StatsAndAiInfoTooltip = () => {
  const [showInfo, setShowInfo] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowInfo(false);
    }, 100);
  };

  return (
    <h4 className="">
      <div
        className="relative inline-block ml-2"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="text-sm italic flex items-center relative cursor-pointer">
          AI-powered research assistant for NBA statistics <span className="text-xs px-1" style={{ fontStyle: 'normal' }}>📊</span>
        </span>
        {showInfo && (
          <div
            className="absolute text-xs left-0 top-6 w-64 bg-white border border-gray-300 rounded shadow-lg p-2 z-10 text-black"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Stats are sourced from&nbsp;
            <a href="https://www.nba.com/stats" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">nba.com/stats</a>
            &nbsp;and updated daily.
            <br />
            <br />
            AI capabilities are powered by&nbsp;
            <a href="https://www.llama.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">Llama</a>
            .
          </div>
        )}
      </div>
    </h4>
  );
};
export { StatsAndAiInfoTooltip };

const QueryInfoTooltip = () => {
  const [showInfo, setShowInfo] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    setShowInfo(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowInfo(false);
    }, 100);
  };

  return (
    <h4 className="">
      <div
        className="relative inline-block ml-1"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="text-sm italic flex items-center relative cursor-pointer">
          Query NBA stats with natural language <span className="text-xs px-1" style={{ fontStyle: 'normal' }}>💡</span>
        </span>
        {showInfo && (
          <div
            className="absolute text-xs left-0 top-6 w-64 bg-white border border-gray-300 rounded shadow-lg p-2 z-10 text-black"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            Under-the-hood, we use AI to convert your query into a&nbsp;
            <a href="https://en.wikipedia.org/wiki/SQL" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">SQL</a>
            &nbsp;query that is then executed against our custom database of NBA stats.
          </div>
        )}
      </div>
    </h4>
  );
};
export { QueryInfoTooltip };

