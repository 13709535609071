import React from 'react';
import PropTypes from 'prop-types';

export const QueryButton = ({ children, onClick, className, ...props }) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-start text-left whitespace-normal break-words ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

QueryButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};