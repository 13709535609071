import React, { useState, useEffect } from 'react';

function AIStatCheck() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    
    // Create preview URL for the image
    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    } else {
      setPreviewUrl(null);
    }
  };

  // Cleanup function to revoke object URL
  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Selected file:', selectedFile);
  };

  return (
    <div className="max-w-md mx-auto mt-8">
      <h1 className="text-2xl font-bold mb-4">AI Stat Check <span role="img" aria-label="camera">📸</span></h1>
      <form onSubmit={handleSubmit}>
        <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          Upload screenshot of NBA stats graphic to check accuracy:
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none p-1.5"
        />
        {previewUrl && (
          <div className="mt-4">
            <img 
              src={previewUrl} 
              alt="Preview" 
              className="max-w-full h-auto rounded-lg shadow-lg"
            />
          </div>
        )}
        <button
          type="submit"
          className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
        >
          Analyze
        </button>
      </form>
      {/* {selectedFile && (
        <p className="mt-4 text-sm text-gray-700 dark:text-gray-300">
          Selected file: {selectedFile.name}
        </p>
      )} */}
    </div>
  );
}

export default AIStatCheck;